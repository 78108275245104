import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UtilityService } from '../../../Shared/services/utility/utility.service';
import {
  AuthenticateUserResponse,
  LoginUserRequest,
  RegisterUserRequest,
  SendOtpRequest,
  SendOtpResponse,
  User
} from '../../models/auth.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.apiUrl;
  userSubject = new BehaviorSubject<User | null>(null);
  tokenExpirationTimeout: number | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private utility: UtilityService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  sendRegisterOtp(data: SendOtpRequest) {
    let url = this.apiUrl + 'customer/signup-send-otp';
    return this.http.post<SendOtpResponse>(url, data);
  }

  registerNewUser(data: RegisterUserRequest) {
    let url = this.apiUrl + 'customer/add-customer';
    return this.http.post<AuthenticateUserResponse>(url, data).pipe(
      tap((res) => {
        this.handleAuthentication(res);
      })
    );
  }

  sendLoginOtp(data: SendOtpRequest) {
    let url = this.apiUrl + 'customer/login-send-otp';
    return this.http.post<SendOtpResponse>(url, data);
  }

  loginUser(data: LoginUserRequest) {
    let url = this.apiUrl + 'customer/login';
    return this.http.post<AuthenticateUserResponse>(url, data).pipe(
      tap((res) => {
        this.handleAuthentication(res);
      })
    );
  }

  private handleAuthentication(res: AuthenticateUserResponse) {
    if (res.status !== 'success') return;
    const user = new User(
      res.data['first_name'],
      res.data['last_name'],
      res.data['mobile_number'],
      res.data['email'],
      res.data['id'],
      res.data['reward_points'],
      res.token,
      new Date(res.expiry_time)
    );

    this.userSubject.next(user);
    this.setAutoLogout(user.timeToExpriy);
    localStorage.setItem('userData', JSON.stringify(user));
    this.router.navigate(['/']);
  }

  logoutUser(source?: string) {
    this.userSubject.next(null);
    if (source === 'manual') {
      this.utility.showSuccessMessage('Succesfully logged out');
    } else {
      this.utility.showErrorMessage(
        'Your token has expired. Please login again.'
      );
    }

    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('userData');
      if (this.tokenExpirationTimeout) {
        clearTimeout(this.tokenExpirationTimeout);
      }
      this.tokenExpirationTimeout = null;
    }

    const currentRouteConfig = this.router.config.find(
      (f) => f.path == this.router.url.slice(1)
    );
    if (currentRouteConfig && currentRouteConfig.canActivate) {
      this.router.navigate(['/']);
    }
  }

  autoLogin() {
    const data = localStorage.getItem('userData');
    if (data) {
      const userData: {
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
        id: string;
        rewardPoints : string;
        _token: string;
        _tokenExpirationDate: string;
      } = JSON.parse(data);

      const loadedUser = new User(
        userData.firstName,
        userData.lastName,
        userData.phone,
        userData.email,
        userData.id,
        +userData.rewardPoints,
        userData._token,
        new Date(userData._tokenExpirationDate)
      );

      if (loadedUser.token) {
        this.userSubject.next(loadedUser);
        this.setAutoLogout(loadedUser.timeToExpriy);
      } else {
        this.logoutUser();
      }
    }
  }

  setAutoLogout(expirationDuration: number) {
    this.tokenExpirationTimeout = window.setTimeout(() => {
      this.logoutUser();
    }, expirationDuration);
  }
}
