import { GenericResponse } from '../../Shared/models/models';

export interface SendOtpRequest {
  email: string;
  mobile_number: string;
  country_code: string;
  source: string;
}
export interface SendOtpResponse extends GenericResponse {}

export interface RegisterUserRequest extends SendOtpRequest {
  first_name: string;
  last_name: string;
  otp: string;
  register_via: string;
}
export interface LoginUserRequest {
  email: string;
  mobile_number: string;
  country_code: string;
  otp: string;
}
export interface AuthenticateUserResponse extends GenericResponse {
  token: string;
  expiry_time: string;
}

export class User {
  constructor(
    public firstName: string,
    public lastName: string,
    public phone: string,
    public email: string,
    public id: string,
    public rewardPoints: number,
    private _token: string,
    private _tokenExpirationDate: Date
  ) {}

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }

  get timeToExpriy() {
    return this._tokenExpirationDate.getTime() - new Date().getTime();
  }
}
