import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  routerSubject = new Subject<boolean>();

  constructor(
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  showSuccessMessage(msg: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.success(msg);
    }
  }
  showErrorMessage(msg: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.error(msg);
    }
  }

  configurePricing(value: string | number) {
    value = value + '';
    let currency = '₹';
    let conversionRate = 1;

    if (value.includes('-')) {
      let lowerValue: string | number = value.split('-')[0].trim();
      let upperValue: string | number = value.split('-')[1].trim();

      lowerValue = +(+lowerValue * +conversionRate).toFixed(2);
      upperValue = +(+upperValue * +conversionRate).toFixed(2);

      lowerValue = lowerValue.toLocaleString('en-IN');
      upperValue = upperValue.toLocaleString('en-IN');

      return `${currency} ${lowerValue} - ${upperValue}`;
    } else {
      let singleValue: number | string = +value;
      singleValue = +(+singleValue * +conversionRate).toFixed(2);
      singleValue = singleValue.toLocaleString('en-IN');

      return `${currency} ${singleValue}`;
    }
  }
}
